/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { SEO } from 'components'
import { Layout } from 'layouts'
import { graphql } from 'gatsby'
import { MapContainer, SideMenu } from 'components'

const Map = ({ data }) => {
  const { title, description, cover, path, markers } =
    data.markdownRemark.frontmatter
  return (
    <>
      <Layout>
        <SEO
          title={title}
          description={description || ''}
          banner={cover.childImageSharp.fluid.src}
          pathname={path}
          article
        />
        <SideMenu markers={markers} title={title} />
        <MapContainer markers={markers} />
      </Layout>
    </>
  )
}

export default Map

export const query = graphql`
  query ($pathSlug: String!) {
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      frontmatter {
        title
        description
        path
        cover {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        markers {
          link
          title
          description
          lat
          lng
        }
      }
    }
  }
`
